//==============================================================================
// Slightly streamlined version of get-related-products
// Returns ProductSearchResults instead of FullProducts
//==============================================================================
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { getRelatedProductsAsync, getRelationTypesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ProductRelationType, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';

import { ProductDetailsCriteria, QueryResultSettingsProxy } from '@msdyn365-commerce-modules/retail-actions';

/**
 * Utility function to prepare the product details criteria before getting full product:
 * @param inputData The Action Input data
 */
 export const getProductDetailsCriteriaFromActionInput = (inputData: ICreateActionContext<IGeneric<IAny>>): ProductDetailsCriteria => {
    if (inputData && inputData.config) {
        return {
            getPrice: !inputData.config.hidePrice,
            getRating: !inputData.config.hideRating
        };
    }
    return {
        getPrice: true,
        getRating: true
    };
};

/**
 * GetRelatedProducts Input Action.
 */
export class GetRelatedProductsInput implements IActionInput {
    public readonly productId: number;

    public readonly catalogId: number;

    public readonly relationType: string;

    public ProductDetailsCriteria: ProductDetailsCriteria;

    public readonly queryResultSettingsProxy: QueryResultSettingsProxy;

    constructor(
        productId: number,
        catalogId: number,
        relationType: string,
        queryResultSettingsProxy: QueryResultSettingsProxy,
        criteria: ProductDetailsCriteria
    ) {
        this.productId = productId;
        this.catalogId = catalogId;
        this.relationType = relationType;
        this.queryResultSettingsProxy = queryResultSettingsProxy;
        this.ProductDetailsCriteria = criteria;
    }

    public getCacheKey = () => `${this.productId}|${this.catalogId}|${this.relationType.toLowerCase()}|${this.queryResultSettingsProxy.cacheKeyHint}|minimal`;

    public getCacheObjectType = () => 'GetRelatedProducts';

    public dataCacheType = (): CacheType => 'none';
}

/**
 * Creates the input required to make GetRelatedProducts retail api call.
 * @param inputData
 */
export const createGetRelatedProductsMinimalInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetRelatedProductsInput => {
    if (inputData && inputData.requestContext && inputData.config) {
        const catalogId = inputData.requestContext.apiSettings.catalogId;
        const relationType = inputData.config.relationType;

        let productId = inputData.requestContext.urlTokens ? Number(inputData.requestContext.urlTokens.recordId) : 0;
        const productDetailsCriteria = getProductDetailsCriteriaFromActionInput(inputData);
        if (!relationType) {
            throw new Error('Input relation type is invalid.');
        }

        // Query string may override the product id from url
        if (inputData.requestContext.query && inputData.requestContext.query.productId) {
            productId = Number(inputData.requestContext.query.productId);
        }

        if (Number.isNaN(productId) || productId <= 0) {
            throw new Error('No valid product id available in url or query string.');
        }

        if (Number.isNaN(catalogId)) {
            throw new Error('Failed to cast catalog id into a number.');
        }

        const queryResultSettingsProxy = QueryResultSettingsProxy.fromInputData(inputData);

        return new GetRelatedProductsInput(productId, catalogId, relationType, queryResultSettingsProxy, productDetailsCriteria);
    }

    throw new Error('Invalid input data or request context');
};

export function searchProductRelationType(productRelationTypes: ProductRelationType[], _productRelationType: string): number | undefined {
    let foundProductRelationTypeId;
    _productRelationType = _productRelationType.toLowerCase();
    productRelationTypes.forEach((productRelationType: ProductRelationType) => {
        if (productRelationType.Name && productRelationType.Name.toLowerCase() === _productRelationType) {
            foundProductRelationTypeId = productRelationType.RecordId;
        }
    });

    return foundProductRelationTypeId;
}

export async function getRelatedProductsMinimalAction(input: GetRelatedProductsInput, ctx: IActionContext): Promise<ProductSearchResult[]> {
    const apiSettings = ctx.requestContext.apiSettings;
    const querySettings = input.queryResultSettingsProxy.QueryResultSettings;

    const productRelationTypes = await getRelationTypesAsync(
        { callerContext: ctx, queryResultSettings: querySettings },
        input.productId,
        +apiSettings.channelId,
        input.catalogId
    );
    if (!productRelationTypes) {
        ctx.trace(`[getProductRelationType] Unable to get product relation types for product ${input.productId}`);
        return <ProductSearchResult[]>[];
    }
    const productRelationTypeId = searchProductRelationType(productRelationTypes, input.relationType);
    if (!productRelationTypeId) {
        ctx.trace(`[getRelatedProducts] Unable to find relation type ${input.relationType} for product ${input.productId}`);
        return <ProductSearchResult[]>[];
    }

    return getRelatedProductsAsync(
        { callerContext: ctx, queryResultSettings: querySettings },
        input.productId,
        +apiSettings.channelId,
        input.catalogId,
        productRelationTypeId
    );
}

/**
 * The getRelatedProducts data action
 * Uses a productId URL Token and finds the relation types for said product
 * using the getRelationTypes RetailServer API, and then finds and retusn all products that
 * share that relation type via the getRelatedProducts RetailServer API.
 */
export const getRelatedProductsActionDataAction = createObservableDataAction({
    id: 'actions/get-related-products-minimal',
    action: <IAction<ProductSearchResult[]>>getRelatedProductsMinimalAction,
    input: createGetRelatedProductsMinimalInput
});

export default getRelatedProductsActionDataAction;
