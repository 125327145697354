//==============================================================================
// Fetches a list of substitute products
//
// Products are sourced from a related products list
// Only products with inventory available are returned
//==============================================================================
import * as Msdyn365 from '@msdyn365-commerce/core';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';

import {
    ArrayExtensions,
    getInventoryLevelCodeFromDimensionValue,
    InventoryLevelValues,
    GetDimensionsForSelectedVariantInput,
    getDimensionsForSelectedVariantAction,
} from '@msdyn365-commerce-modules/retail-actions';

import { createGetRelatedProductsMinimalInput, getRelatedProductsMinimalAction } from '../../../actions/get-related-products-minimal';

import { ISubstituteProductsConfig } from '../substitute-products.props.autogenerated';

//==============================================================================
// INTERFACES
//==============================================================================

//==============================================================================
// FUNCTIONS
//==============================================================================

//==========================================================
// GetSubstituteProduct Input Action
//==========================================================
export class GetSubstituteProductInput implements Msdyn365.IActionInput {
    public readonly context: Msdyn365.ICreateActionContext<ISubstituteProductsConfig>;

    //----------------------------------------------------------
    //----------------------------------------------------------
    constructor(
        context: Msdyn365.ICreateActionContext<ISubstituteProductsConfig>,
    ) {
        this.context = context;
    }

    //----------------------------------------------------------
    // Caching Stuff
    //----------------------------------------------------------
    public getCacheKey = () => `${this.context.config?.productCollection || ''}|${this.context.config?.maxProductsToShow || 0}`;
    public getCacheObjectType = () => 'SubstituteProducts';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

//==========================================================
//==========================================================
const createSubstituteProductsInput = (actionContext: Msdyn365.ICreateActionContext<ISubstituteProductsConfig>): Msdyn365.IActionInput => {
    return new GetSubstituteProductInput(actionContext);
};

//==========================================================
// This assumes that there are no service items and the list is unique.
// This was borrowed from get-product-availabilities-wishlist-items
// but the extra checks were removed since they shouldn't be
// needed in this context.
//==========================================================
async function getAvailabilities(productList: number[], ctx: Msdyn365.IActionContext): Promise<Boolean[]> {

    // Initiate a bunch of parallel requests (unfortunate!)
    const promises = productList.map(id => {
        const input = new GetDimensionsForSelectedVariantInput(id, ctx.requestContext.apiSettings.channelId);
        return getDimensionsForSelectedVariantAction(input, ctx);
    });

    // Wait for all requests to complete
    const result = await Promise.all(promises);

    // Convert results into a simple true/false for product availability
    return result.map(entry => {
        const dimensionValuesWithInventory = ArrayExtensions.flatten(
            ArrayExtensions.validValues(entry.map(value => value.dimensionValuesWithInventory)));
        const hasAvailableProducts = !ArrayExtensions.hasElements(dimensionValuesWithInventory) || dimensionValuesWithInventory.some(
            value => {
                const inventoryLevelCode = getInventoryLevelCodeFromDimensionValue(value, ctx.requestContext.app.config.inventoryLevel);
                return inventoryLevelCode !== InventoryLevelValues.outOfStock;
            }
        );

        return hasAvailableProducts;
    });
}

//==========================================================
//==========================================================
async function getSubstituteProductsAction(input: GetSubstituteProductInput, ctx: Msdyn365.IActionContext): Promise<ProductSearchResult[] | undefined> {

    // If we don't have a related products list name, we can't do anything
    if (!input.context.config?.productCollection) {
        return undefined;
    }

    //     // @REMOVEME/dg: DEBUG!
    //     ctx.requestContext.app.config.enableStockCheck = true;
    //     ctx.requestContext.app.config.inventoryLevel = 'totalAvailable';

    // Fetch the related products list
    // The get-related-products data action returns FullProduct results, which is more info than needed. Use a more lightweight solution.
    // @ts-expect-error
    input.context.config.relationType = input.context.config.productCollection;
    const relatedProductsInput = createGetRelatedProductsMinimalInput(input.context);
    let relatedProducts = await getRelatedProductsMinimalAction(relatedProductsInput, ctx);

    // Pull the record IDs from the related product list and truncate to a max length of maxProductsToShow
    const productList = relatedProducts && relatedProducts.map(entry => entry.RecordId).slice(0, input.context.config.maxProductsToShow);
    if (!productList || !productList.length) {
        return;
    }

    // Filter out products with no availability (only if inventory checking is enabled)
    if (ctx.requestContext.app.config.enableStockCheck) {

        // Fetch availability information for the products in the list
        const availabilities = await getAvailabilities(productList, ctx);

        // Filter out unavailable products
        relatedProducts = relatedProducts.filter((product, index) => {
            return availabilities[index];
        });
    }

    return relatedProducts;
}

//==============================================================================
//==============================================================================
export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<ProductSearchResult[]>>getSubstituteProductsAction,
    id: 'substitute-products/GetSubstituteProducts',
    input: createSubstituteProductsInput
});
